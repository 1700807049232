import logo from './logo.svg';
import './App.css';
import { Main } from './Pages/Main';


function App() {
  return (
    <>
      <Main></Main>
    </>
  );
}

export default App;
